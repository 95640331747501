import clsx from 'clsx';
import { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { FormControl, FormHelperText } from '@mui/material';
import { BaseTextFieldProps } from '@mui/material/TextField';
import { get, useFormContext, Controller } from 'react-hook-form';
import TextField from './TextField';
import FormLabel from './FormLabel';
import LabelText from './LabelText';

interface TextFieldProps
	extends Omit<
		BaseTextFieldProps,
		'name' | 'label' | 'onChange' | 'InputProps' | 'inputProps'
	> {
	name: string;
	label: string;
	tooltip?: string;
	styleSize?: string;
	startAdornment?: string | ReactElement;
	endAdornment?: string | ReactElement;
	InputProps?: any;
	inputProps?: any;
	maxLength?: number;
	readOnly?: boolean;
	onChange?: Function;
	readOnlyRender?: Function;
}

const InputField = ({
	name,
	label,
	tooltip,
	value,
	styleSize,
	required,
	fullWidth,
	onChange,
	maxLength,
	inputProps,
	startAdornment,
	endAdornment,
	disabled,
	readOnly,
	InputProps,
	readOnlyRender,
	...props
}: TextFieldProps) => {
	const {
		control,
		formState: { errors },
		setValue,
	} = useFormContext();
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange: onFieldChange, value } }) => {
				const inputValue = value ?? '';
				const errorMsg = get(errors, name)?.message;
				const isError = !!errorMsg;
				if (readOnly)
					return (
						<LabelText
							label={label}
							content={readOnlyRender ? readOnlyRender(inputValue) : inputValue}
						/>
					);
				return (
					<FormControl
						error={isError}
						sx={{ width: fullWidth ? '100%' : 'auto' }}
					>
						<TextField
							className={clsx([styleSize && `Customized-${styleSize}`])}
							label={
								<FormLabel
									required={required}
									label={label}
									tooltip={tooltip}
								/>
							}
							name={name}
							value={inputValue}
							error={isError}
							fullWidth={fullWidth}
							onChange={(e: any) => {
								onChange ? onChange(e.target.value) : onFieldChange(e);
							}}
							onBlur={(e) => {
								const value = e.target.value;
								const trimValue = value.trim();
								if (trimValue !== value) {
									setValue(name, trimValue);
								}
							}}
							InputProps={{
								startAdornment: startAdornment && (
									<InputAdornment position='start'>
										{typeof startAdornment === 'string' ? (
											<Typography variant='body2'>{startAdornment}</Typography>
										) : (
											{ startAdornment }
										)}
									</InputAdornment>
								),
								endAdornment: endAdornment && (
									<InputAdornment position='end'>{endAdornment}</InputAdornment>
								),
								...InputProps,
							}}
							inputProps={{
								maxLength,
								...inputProps,
							}}
							disabled={readOnly || disabled}
							{...props}
						/>
						<FormHelperText>{errorMsg}</FormHelperText>
					</FormControl>
				);
			}}
		/>
	);
};

InputField.defaultProps = {
	type: 'text',
	disabled: false,
	readOnly: false,
	fullWidth: true,
	required: true,
	maxLength: 50,
	variant: 'standard',
	startAdornment: '',
	endAdornment: '',
};

export default InputField;
