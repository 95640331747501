import { get, useFormContext, Controller } from 'react-hook-form';
import {
	FormControl,
	FormHelperText,
	Typography,
	InputLabel,
	ListSubheader,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Select, { SelectProps as MuiSelecProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from './FormLabel';

interface Option<T> {
	isHeader?: boolean;
	label: string;
	value?: string | number;
	data?: T;
}

interface SelectFieldProps<T> {
	name: string;
	label?: string;
	tooltip?: string;
	styleSize?: string;
	options: Option<T>[];
	required?: boolean;
	disabled?: boolean;
	variant?: MuiSelecProps['variant'];
	onChange?: Function;
	placeholder?: string;
	onOptionChange?: Function;
	readOnly?: boolean;
}

const SelectField = <T extends unknown>({
	name,
	label,
	tooltip,
	styleSize,
	options,
	required,
	disabled,
	variant,
	onChange,
	placeholder,
	onOptionChange,
	readOnly,
}: SelectFieldProps<T>) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const styles = useStyles(styleSize, readOnly, disabled);
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange: onFieldChange, ref, ...fieldProps } }) => {
				const errorMsg = get(errors, name)?.message;
				const isError = !!errorMsg;
				const handleOptionChange = (v: any) => {
					if (onOptionChange) {
						const option = options.find((opt) => opt.value === v);
						onOptionChange(option);
					}
				};

				return (
					<FormControl error={isError} sx={{ width: '100%' }}>
						<InputLabel sx={styles.labelSx}>
							<FormLabel required={required} label={label} tooltip={tooltip} />
						</InputLabel>
						<Selector
							innerRef={ref}
							options={options}
							disabled={disabled}
							placeholder={placeholder}
							variant={variant}
							readOnly={readOnly}
							styleSize={styleSize}
							onChange={(e) => {
								if (onChange) {
									onChange(e.target.value);
								} else {
									onFieldChange(e);
									handleOptionChange(e.target.value);
								}
							}}
							{...fieldProps}
						/>
						<FormHelperText>{errorMsg}</FormHelperText>
					</FormControl>
				);
			}}
		/>
	);
};

SelectField.defaultProps = {
	variant: 'standard',
};

interface SelectorProps<T> extends MuiSelecProps {
	placeholder?: string;
	options: Option<T>[];
	innerRef?: any;
	styleSize?: string;
	readOnly?: boolean;
}

const Selector = <T extends unknown>({
	styleSize,
	placeholder,
	options,
	innerRef,
	readOnly,
	disabled,
	...props
}: SelectorProps<T>) => {
	const styles = useStyles(styleSize, readOnly, disabled);
	return (
		<Select
			displayEmpty
			ref={innerRef}
			size='small'
			sx={styles.selectSx}
			MenuProps={{
				sx: styles.menuSx,
			}}
			IconComponent={KeyboardArrowDownIcon}
			inputProps={{
				readOnly,
			}}
			disabled={disabled}
			{...props}
		>
			{placeholder && (
				<MenuItem disabled value=''>
					<Typography variant='inherit' sx={styles.menuItemSx}>
						{placeholder}
					</Typography>
				</MenuItem>
			)}
			{options?.map((item) => {
				if (item.isHeader) {
					return (
						<ListSubheader key={item.label} sx={styles.menuHeaderSx}>
							<Typography
								// @ts-ignore-start
								variant='label'
								// @ts-ignore-end
								color='color.black60'
							>
								{item.label}
							</Typography>
						</ListSubheader>
					);
				}
				return (
					<MenuItem key={item.label} value={item.value} sx={styles.menuItemSx}>
						{item.label}
					</MenuItem>
				);
			})}
		</Select>
	);
};

const useStyles = (styleSize: any, readOnly: any, disabled?: boolean) => {
	const isSmall = styleSize === 'small';
	return {
		labelSx: {
			transform: isSmall
				? 'translate(14px, 10px) scale(1)'
				: 'translate(14px, 16px) scale(1)',
			'& label': {
				fontSize: '16px',
				color: disabled ? '#918C8A' : 'color.black60',
				lineHeight: '22px',
			},
			'&.Mui-error label': {
				color: 'color.black',
			},
			'&.Mui-focused label': {
				color: 'color.black60',
			},
			'&.MuiInputLabel-shrink': {
				top: '15px',
				transform: isSmall
					? 'translate(14px, -13px) scale(0.75)'
					: 'translate(14px, -9px) scale(0.75)',
			},
		},
		selectSx: {
			minHeight: isSmall ? '40px' : '56px',
			margin: '0 !important',
			color: 'color.black',
			bgcolor: 'white',
			border: readOnly ? 0 : '1px solid',
			borderColor: 'color.black8',
			borderRadius: '8px',
			overflow: 'hidden',
			'&.Mui-focused': {
				borderColor: 'color.allyBlue',
			},
			'&.Mui-disabled': {
				backgroundColor: '#EDEDEC !important',
				borderColor: 'transparent !important',
				WebkitTextFillColor: 'inherit !important',
				'& .MuiInputBase-input': {
					color: '#A7A3A2',
					WebkitTextFillColor: 'inherit',
				},
				'& .MuiSvgIcon-root': {
					display: 'none',
				},
			},
			'& fieldset': {
				border: ' 0 !important',
			},
			'&.Mui-error': {
				borderColor: 'color.error',
			},
			'& .MuiSelect-select': {
				padding: 0,
				paddingLeft: '12px',
				paddingTop: isSmall ? '12px' : '20px',
			},
			'& .MuiSelect-select:focus': {
				background: 'inherit',
			},
			'& .MuiSvgIcon-root': {
				color: 'color.black',
				right: '8px',
			},
			'& .MuiSelect-icon': {
				visibility: readOnly ? 'hidden' : 'visible',
			},
			'&:before': {
				display: 'none',
			},
			'&:after': {
				display: 'none',
			},
		},
		menuSx: {
			top: '2px',
			'& .MuiMenu-paper': {
				borderRadius: '8px',
				border: '1px solid',
				borderColor: 'color.background',
				boxShadow:
					'0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)',
			},
		},
		menuItemSx: {
			fontFamily: 'PingFang TC',
			fontSize: '12px',
			margin: '0 8px',
			padding: '8px',
			borderRadius: '8px',
			fontWeight: 400,
			lineHeight: '17px',
			'&.Mui-selected': { bgcolor: 'color.divider' },
		},
		menuHeaderSx: {
			lineHeight: '32px',
		},
	};
};

export { Selector, SelectField };
