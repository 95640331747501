import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ListItem, SxProps, Box } from '@mui/material';
import { get, useFormContext, Controller } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormLabel from './FormLabel';
import TextField from './TextField';
import Loading from './Loading';

interface Option<T = any> {
	title: string;
	inputValue?: string;
	data?: T;
}

interface ISearchCreateField<T> {
	name: string;
	value?: string;
	label: string;
	styleSize?: string;
	required: boolean;
	disabled?: boolean;
	freeSolo?: boolean;
	clearOnBlur?: boolean;
	selectOnFocus?: boolean;
	options: Option<T>[];
	loading?: boolean;
	onCreate: Function;
	onChange?: Function;
	placeholder?: string;
	defaultValue?: Option;
	createOptionText?: string;
	createOptionColor?: string;
	includeInputInList?: boolean;
	sx?: SxProps;
	inputSx?: SxProps;
	getOptionDisabled?: (option: any) => boolean;
}

const filter = createFilterOptions<Option>();

const SearchCreateField = <T,>({
	label = '',
	name,
	required,
	onChange,
	placeholder,
	onCreate,
	styleSize,
	createOptionText,
	freeSolo = false,
	clearOnBlur = true,
	selectOnFocus = true,
	includeInputInList = true,
	createOptionColor = 'color.info',
	disabled = false,
	getOptionDisabled = () => false,
	...props
}: ISearchCreateField<T>) => {
	const { t } = useTranslation();
	const {
		control,
		formState: { errors },
		trigger,
	} = useFormContext();
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange: onFieldChange } }) => {
				const errorMsg = get(errors, name)?.message;
				const isError = !!errorMsg;

				return (
					<Autocomplete
						freeSolo={freeSolo}
						value={value}
						disabled={disabled}
						PaperComponent={({ ...p }) => (
							<Box
								{...p}
								sx={{
									backgroundColor: 'white',
									boxShadow:
										'0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)',
									borderRadius: '8px',
								}}
							/>
						)}
						popupIcon={<KeyboardArrowDownIcon />}
						noOptionsText={t('noOptions')}
						loadingText={<Loading />}
						onChange={async (e: any, newValue: any) => {
							let value = '',
								needCreate = false;
							if (typeof newValue === 'string') {
								value = newValue;
							} else if (newValue?.inputValue) {
								value = newValue.inputValue;
								needCreate = true;
							} else {
								value = newValue?.title ?? '';
							}
							onChange ? onChange(value) : onFieldChange(value);
							if (!needCreate) return;
							const result = await trigger(name);
							if (!result) return;
							onCreate(value);
						}}
						filterOptions={(options: Option[], params: any) => {
							const filtered = filter(options, params);
							const { inputValue } = params;
							const isExisting = options.some(
								(option: any) => inputValue == option.title
							);
							if (inputValue !== '' && !isExisting) {
								filtered.push({
									inputValue,
									title: `${createOptionText} ${inputValue}`,
								});
							}
							return filtered;
						}}
						getOptionLabel={(option: any) => {
							if (typeof option === 'string') {
								return option;
							}
							if (option.inputValue) {
								return option.inputValue;
							}
							return option.title;
						}}
						isOptionEqualToValue={(option: any, value: any) => {
							if (typeof option === 'string') {
								return option === value;
							}
							if (option.inputValue) {
								return option.inputValue === value;
							}
							return option.title === value;
						}}
						renderOption={(props, option) => {
							return (
								<ListItem
									sx={{
										color:
											option.inputValue === option.title
												? 'inherit'
												: createOptionColor,
									}}
									{...props}
								>
									{option.title}
								</ListItem>
							);
						}}
						renderInput={(params) => {
							return (
								<TextField
									{...params}
									label={<FormLabel required={required} label={label} />}
									disabled={disabled}
									error={isError}
									helperText={errorMsg}
									placeholder={placeholder}
									variant={label ? 'standard' : 'outlined'}
									className={clsx(styleSize && `Customized-${styleSize}`)}
								/>
							);
						}}
						clearOnBlur={clearOnBlur}
						selectOnFocus={selectOnFocus}
						includeInputInList={includeInputInList}
						getOptionDisabled={getOptionDisabled}
						{...props}
					/>
				);
			}}
		/>
	);
};

export default SearchCreateField;
