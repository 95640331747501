import { useCallback } from 'react';
import { get, Controller, useFormContext } from 'react-hook-form';
import MuiCheckbox, {
	CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';
import {
	FormControl,
	FormHelperText,
	FormControlLabel,
	FormGroup,
} from '@mui/material';
import FormLabel from './FormLabel';

interface CheckboxFieldProps {
	ariaLabel?: string;
	name: string;
	label?: string;
	tooltip?: string;
	direction?: 'row' | 'column';
	onChange?: Function;
	options: CheckboxProps[];
	checkAll?: { label: string };
	required?: boolean;
	disabled?: boolean;
}

const CheckboxField = ({
	ariaLabel,
	name,
	label,
	tooltip,
	direction = 'row',
	onChange,
	options,
	required,
	checkAll,
	disabled = false,
}: CheckboxFieldProps) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({
				field: { onChange: onFieldChange, value: fieldValues, ...fieldProps },
			}) => {
				const errorMsg = get(errors, name)?.message;
				const isError = !!errorMsg;
				const isCheckAll = fieldValues.length === options.length;
				const handleCheck = (value: string) => {
					if (onChange) return onChange(value);
					const newFieldValues = fieldValues?.includes(value)
						? fieldValues?.filter((fieldValue: string) => fieldValue !== value)
						: [...fieldValues, value];
					onFieldChange(newFieldValues);
				};

				const handleCheckAll = useCallback(() => {
					const newFieldValues = isCheckAll
						? []
						: options.map((option) => option.value);
					onFieldChange(newFieldValues);
				}, [isCheckAll]);

				return (
					<FormControl component='fieldset' error={isError}>
						<FormLabel required={required} label={label} tooltip={tooltip} />
						<FormGroup
							aria-label={ariaLabel || name}
							row={direction === 'row'}
							{...fieldProps}
						>
							{checkAll && (
								<Checkbox
									key={`checkbox-${checkAll.label}`}
									checked={fieldValues.length === options.length}
									onChange={() => handleCheckAll()}
									value={'all'}
									label={checkAll.label}
									disabled={disabled}
								/>
							)}
							{options?.map((option) => (
								<Checkbox
									key={`checkbox-${option.label}`}
									checked={fieldValues.includes(option.value)}
									onChange={(e) => {
										handleCheck(e.target.value);
									}}
									disabled={disabled}
									{...option}
								/>
							))}
						</FormGroup>
						<FormHelperText>{errorMsg}</FormHelperText>
					</FormControl>
				);
			}}
		></Controller>
	);
};

interface CheckboxProps extends MuiCheckboxProps {
	value: string | number;
	label: string;
	disabled?: boolean;
	labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
}

const Checkbox = ({
	value,
	label,
	labelPlacement,
	checked,
	onChange,
	...muiCheckboxProps
}: CheckboxProps) => {
	return (
		<FormControlLabel
			value={value}
			control={
				<MuiCheckbox
					checked={checked}
					onChange={onChange}
					{...muiCheckboxProps}
				/>
			}
			label={label}
			labelPlacement={labelPlacement}
		/>
	);
};

export { CheckboxField, Checkbox };
