import { useState } from 'react';
import { get, useFormContext, Controller } from 'react-hook-form';
import { FormControl, FormHelperText, Box, Chip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FormLabel from './FormLabel';
import TextField from './TextField';

const TAB = 'Tab';
const ENTER = 'Enter';
const BACKSPACE = 'Backspace';

interface TagInputFieldProps {
	name: string;
	label?: string;
	tooltip?: string;
	unique: boolean;
	required: boolean;
	disabled: boolean;
	delimiters: string[];
	placeholder?: string;
	onChange?: Function;
}

const TagInputField = ({
	name,
	label,
	tooltip,
	unique,
	required,
	disabled,
	delimiters,
	placeholder,
	onChange,
}: TagInputFieldProps) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const [inputCandidate, setInputCandidate] = useState('');

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange: onFieldChange, value = [] } }) => {
				const errorMsg = get(errors, name)?.message;
				const isError = !!errorMsg;
				const updateValues = (
					inputValue: string | string[] = inputCandidate
				) => {
					if (inputValue) {
						let nextValue: any = value.concat(inputValue);
						if (unique) {
							nextValue = Array.from(new Set(nextValue));
						}
						if (onChange) {
							onChange(nextValue);
						} else {
							onFieldChange(nextValue);
						}
						setInputCandidate('');
					}
				};
				return (
					<FormControl error={isError} sx={{ width: '100%' }}>
						<TextField
							fullWidth
							label={
								<FormLabel
									required={required}
									label={label}
									tooltip={tooltip}
								/>
							}
							type='text'
							variant='standard'
							name={name}
							error={isError}
							disabled={disabled}
							value={inputCandidate}
							placeholder={value.length > 0 ? undefined : placeholder}
							inputProps={{
								style: {
									width:
										value.length > 0
											? `${inputCandidate.length + 1}em`
											: '100%',
								},
							}}
							InputProps={{
								startAdornment: (
									<Box className='TagInput-Box'>
										{value.map((title: string, index: number) => (
											<Chip
												key={index}
												className='TagInput-MuiChip'
												label={title}
												onDelete={
													disabled
														? undefined
														: () => {
																const nextValue = value.filter(
																	(_: string, idx: number) => idx !== index
																);
																if (onChange) {
																	onChange(nextValue);
																} else {
																	onFieldChange(nextValue);
																}
														  }
												}
												sx={{
													height: '20px',
													margin: '0 5px 5px 0',
												}}
												deleteIcon={<ClearIcon />}
											/>
										))}
									</Box>
								),
								sx: {
									overflow: 'hidden',
									flexFlow: 'row wrap',
									paddingBottom: 0,
								},
								onChange: (e) => {
									setInputCandidate(e.target.value);
								},
								onKeyDown: (e) => {
									if (e.key === BACKSPACE && !e.currentTarget.value) {
										onFieldChange(value.slice(0, value.length - 1));
									} else if (delimiters.includes(e.key)) {
										e.preventDefault();
										updateValues();
									}
								},
								onBlur: () => {
									updateValues();
								},
								onPaste: (e) => {
									e.preventDefault();
									const inputValue = e.clipboardData
										.getData('Text')
										.replace(/\r?\n|\r/g, '')
										.split(',')
										.filter((text) => text);
									updateValues(inputValue);
								},
							}}
						/>
						<FormHelperText>{errorMsg}</FormHelperText>
					</FormControl>
				);
			}}
		/>
	);
};

TagInputField.defaultProps = {
	unique: true,
	disabled: false,
	required: false,
	delimiters: [TAB, ENTER],
};

export default TagInputField;
