export const apiURL =
	window.location.hostname !== 'localhost'
		? `${window.location.protocol}//api.${window.location.hostname}/restful`
		: 'https://api.space-dev.allytransport.com.tw/restful';

export const imageURL =
	window.location.hostname !== 'localhost'
		? `${window.location.protocol}//${window.location.hostname}/images`
		: 'https://space-dev.allytransport.com.tw/images';

export const isPorterURL = process.env.REACT_APP_WEBSITE === 'porter';

export const isAdminURL = process.env.REACT_APP_WEBSITE === 'admin';

export const isCarrierURL = process.env.REACT_APP_WEBSITE === 'carrier';

export enum SYSTEM_TYPE_ENUM {
	ADMIN = 'ADMIN',
	SHIPPER = 'SHIPPER',
	CARRIER = 'CARRIER',
}

export const systemType = isPorterURL
	? SYSTEM_TYPE_ENUM.SHIPPER
	: isAdminURL
	? SYSTEM_TYPE_ENUM.ADMIN
	: SYSTEM_TYPE_ENUM.CARRIER;
