import { ReactNode } from 'react';
import Link, { LinkProps } from '@mui/material/Link';

interface LinkButtonProps extends LinkProps {
	text: string | ReactNode;
}

const LinkButton = ({
	text,
	sx,
	underline = 'none',
	...props
}: LinkButtonProps) => (
	<Link
		underline={underline}
		sx={{
			wordBreak: 'keep-all',
			cursor: 'pointer',
			'&:hover': {
				opacity: 0.6,
			},
			'&:disabled': {
				opacity: 0.2,
				cursor: 'not-allowed',
			},
			...sx,
		}}
		{...props}
	>
		{text}
	</Link>
);
export default LinkButton;
