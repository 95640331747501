import { ReactNode } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { Box, DialogActions, DialogContent } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button, { ButtonProps } from './Button';
import { useTranslation } from 'react-i18next';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { SxProps } from '@mui/system';

interface DialogProps extends Omit<MuiDialogProps, 'title' | 'onClose'> {
	type?: string;
	title: string | ReactNode;
	onConfirm?: Function;
	confirmText?: string;
	confirmButtonStyle?: SxProps;
	cancelText?: string;
	cancelButtonType: ButtonProps['variant'];
	confirmButtonType: ButtonProps['variant'];
	titleType?: TypographyProps['variant'];
	children?: ReactNode;
	actionsDivider?: boolean;
	actionsSx?: SxProps;
	loading?: boolean;
	onLoadingEnd?: Function;
	blockConfirmButton?: boolean;
	onClose?: Function;
	titleContainerSx?: SxProps;
	elementBeforeButton?: ReactNode;
}

const Dialog = ({
	type,
	title,
	onClose,
	onConfirm,
	confirmText,
	cancelText,
	cancelButtonType,
	confirmButtonType,
	titleType,
	children,
	actionsDivider = true,
	actionsSx,
	titleContainerSx,
	confirmButtonStyle,
	loading = false,
	onLoadingEnd,
	blockConfirmButton,
	elementBeforeButton,
	...props
}: DialogProps) => {
	const styles = useStyles();
	const { t } = useTranslation();
	const prevLoading = usePrevious(loading);
	const isInfo = type === 'confirm';

	useUpdateEffect(() => {
		if (prevLoading && !loading && onLoadingEnd) {
			onLoadingEnd();
		}
	}, [loading]);
	return (
		<MuiDialog
			onClose={() => {
				if (onClose) onClose();
			}}
			{...props}
		>
			<Box sx={{ py: 5, px: 2.5 }}>
				<Box sx={{ mb: 2.5, ...titleContainerSx }}>
					{typeof title === 'string' ? (
						<Typography variant={titleType}>{title}</Typography>
					) : (
						title
					)}
				</Box>
				<DialogContent sx={{ p: 0 }}>{children}</DialogContent>
			</Box>
			{actionsDivider && (
				<Divider sx={{ backgroundColor: 'color.background', mx: 2.5 }} />
			)}
			{elementBeforeButton}
			<DialogActions sx={{ p: 2.5, ...styles.infoFooter, ...actionsSx }}>
				{isInfo && (
					<Button
						variant={cancelButtonType}
						onClick={onClose}
						sx={{ mr: 1, ...styles.button }}
					>
						{cancelText || t('cancelText')}
					</Button>
				)}
				<Button
					variant={confirmButtonType}
					disabled={loading || blockConfirmButton}
					loading={loading}
					onClick={onConfirm}
					sx={{ ...styles.button, ...confirmButtonStyle }}
				>
					{confirmText || t('confirmText')}
				</Button>
			</DialogActions>
		</MuiDialog>
	);
};

const useStyles = () => ({
	button: {
		width: '140px',
	},
	infoFooter: {
		width: '100%',
		justifyContent: 'flex-end',
	},
});

Dialog.defaultProps = {
	type: 'ask',
	open: false,
	maxWidth: 'md',
	confirmButtonType: 'contained',
	cancelButtonType: 'ghost',
	titleType: 'h5',
	blockConfirmButton: false,
};

export default Dialog;
