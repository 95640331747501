import * as yup from 'yup';
import i18next from 'i18next';
import googleLibphonenumber from 'google-libphonenumber';
import secondsToHours from 'date-fns/secondsToHours';
import { MAX_BILL_CHARGE } from '~/enums/app';
import { startOfTodayUnixTime } from '~/utils/date';

const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance();
const regionCode = i18next.language.split('-')[1];

yup.setLocale({
	number: {
		max: ({ max }) => i18next.t('schema:number.max', { max }),
		moreThan: ({ more }) => `${more}`,
	},
	string: {
		max: ({ max }) => i18next.t('schema:string.max', { max }),
		email: i18next.t('schema:email'),
	},
	mixed: {
		required: i18next.t('schema:required'),
		notType: ({ path, type, value }) => {
			return i18next.t('schema:typeError');
		},
	},
});

// Yup plugins
yup.addMethod<yup.ObjectSchema<any>>(
	yup.object,
	'castOrDefault',
	function (value = {}): any {
		const option = {
			stripUnknown: true,
		};
		try {
			return this.cast(value, option);
		} catch (err) {
			return this.cast({}, option);
		}
	}
);

export const paginationSchema = (offset = 0, limit = 25) =>
	yup.object({
		offset: yup.number().default(offset),
		limit: yup.number().default(limit),
	});

export const passwordSchema = yup
	.string()
	.required()
	.min(8, i18next.t('schema:password', { min: 8 }))
	.max(32, i18next.t('schema:string.max', { max: 32 }))
	.matches(
		/^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,}$/,
		i18next.t('schema:password', { min: 8 })
	);

export const confirmPasswordSchema = (key: string) =>
	yup
		.string()
		.oneOf([yup.ref(key), null], i18next.t('schema:password.confirm'));

export const emailSchema = yup
	.string()
	.default('')
	.nullable()
	.email(i18next.t('schema:email'));

export const emailArraySchema = yup
	.array()
	.ensure()
	.default([])
	.test(function (emails: string[]) {
		const isValid = emails.every((email) =>
			yup.string().email().isValidSync(email)
		);
		if (!isValid) {
			return this.createError({
				path: this.path,
				message: i18next.t('schema:email'),
			});
		}
		return isValid;
	});

export const unifiedBusinessNoSchema = yup
	.string()
	.default('')
	.test(function (value) {
		const isValid = value.length === 8 && /^[0-9]*$/.test(value);
		if (value && !isValid) {
			return this.createError({
				path: this.path,
				message: i18next.t('schema:unifiedBusinessNo'),
			});
		}
		return true;
	});

export const positiveNumberSchema = yup
	.string()
	.default('')
	.nullable()
	.test('is-zero', i18next.t('schema:noZeroAllow'), (value) =>
		value ? Number(value) !== 0 : true
	)
	.test((value, context) => {
		const isValid = value !== null && /^\d*[1-9]\d*$/.test(value);
		if (value && !isValid) {
			return context.createError({
				message: i18next.t('positiveNumber'),
			});
		}
		return true;
	});

interface INnumberSchema {
	min?: number;
	minMessage?: string;
	max?: number;
	maxMessage?: string;
	isPositive?: boolean;
	positiveMessage?: string;
	isNegative?: boolean;
	negativeMessage?: string;
	isInteger?: boolean;
	integerMessage?: string;
	step?: number;
}

export const numberSchema = (props: INnumberSchema = {}) => {
	const {
		min,
		max,
		step,
		isPositive = false,
		isNegative = false,
		isInteger = false,
	} = props;
	let schema = yup
		.number()
		.transform((value, originalvalue) => {
			if (originalvalue === '' || originalvalue === undefined) return null;
			return value;
		})
		.nullable()
		.default(null)
		.test((value, context) => {
			if (value === null) return true;
			if (min !== undefined && value < min) {
				const minMessage =
					props.minMessage || i18next.t('schema:number.min', { min });
				return context.createError({
					message: minMessage,
				});
			} else if (max !== undefined && value > max) {
				const maxMessage =
					props.maxMessage || i18next.t('schema:number.max', { max });
				return context.createError({
					message: maxMessage,
				});
			}
			if (step && value % step !== 0) {
				return context.createError({
					message: i18next.t('schema:number.step', { step }),
				});
			}
			return true;
		})
		.typeError(i18next.t('schema:numberOnly'));
	if (isPositive) {
		const positiveMessage =
			props.positiveMessage || i18next.t('schema:number.positive');
		schema = schema.positive(positiveMessage);
	}
	if (isNegative && !isPositive) {
		const negativeMessage =
			props.negativeMessage || i18next.t('schema:number.negative');
		schema = schema.negative(negativeMessage);
	}
	if (isInteger) {
		const integerMessage =
			props.integerMessage || i18next.t('schema:numberOnly');
		schema = schema.integer(integerMessage);
	}
	return schema;
};

export const zeroAndPositiveNumberSchema = numberSchema({
	min: 0,
	max: MAX_BILL_CHARGE,
	isInteger: true,
});

interface IAmountSchema {
	min: number;
	max: number;
}

export const amountSchema = (props: IAmountSchema) => {
	const { min, max } = props;
	const msg = i18next.t('schema:number.range', { min, max });
	return numberSchema({
		min,
		max,
		minMessage: msg,
		maxMessage: msg,
		isInteger: true,
	});
};

export const phoneNumberSchema = yup
	.string()
	.default('')
	.test((value, context) => {
		const isValid = !value || /^\d+$/.test(value);
		if (!isValid) {
			return context.createError({
				message: i18next.t('schema:numberOnly'),
			});
		}
		return true;
	})
	.test((value, context) => {
		if (!value) return true;
		let isValid;
		try {
			isValid =
				value[0] === '0' &&
				phoneUtil.isValidNumberForRegion(
					phoneUtil.parseAndKeepRawInput(value, regionCode),
					regionCode
				);
		} catch (e) {
			isValid = false;
		}
		if (!isValid) {
			return context.createError({
				message: i18next.t('schema:methodError', {
					method: i18next.t('phone'),
				}),
			});
		}
		return true;
	});

export const mobilePhoneSchema = yup
	.string()
	.default('')
	.test((value, context) => {
		let isValid;
		const data = value.replaceAll('-', '');
		try {
			isValid = /^09[0-9]*$/.test(data);
		} catch (e) {
			isValid = false;
		}
		if (data && !isValid) {
			return context.createError({
				message: i18next.t('schema:typeError'),
			});
		}
		return true;
	});

type WindowTimeFieldType = 'end' | 'start';

export const getWindowTimeSchema = (
	type: WindowTimeFieldType,
	interval?: number | undefined
) => {
	const intervalWarningText = interval
		? i18next.t('shippingOrder:timeIntervalLimit', {
				hour: secondsToHours(interval),
		  })
		: '';
	const warningText =
		type === 'end'
			? i18next.t('shippingOrder:timeWindowEndMustAfterStart')
			: i18next.t('shippingOrder:timeWindowStartMustBeforeEnd');
	return yup
		.string()
		.nullable()
		.default('')
		.test('is-windowTime-valid', warningText, (value, testContext) => {
			let isValid = true;
			if (type === 'start') {
				const end = testContext.parent.end;
				isValid = value && end ? Number(end) > Number(value) : true;
			}
			if (type === 'end') {
				const start = testContext.parent.start;
				isValid = value && start ? Number(start) < Number(value) : true;
			}
			return isValid;
		})
		.test(
			'is-windowTime-interval-valid',
			intervalWarningText,
			(value, testContext) => {
				if (!interval) return true;
				let isValid = true;
				if (type === 'start') {
					const end = testContext.parent.end;
					isValid =
						value && end ? Number(end) - Number(value) >= interval : true;
				}
				if (type === 'end') {
					const start = testContext.parent.start;
					isValid =
						value && start ? Number(value) - Number(start) >= interval : true;
				}
				return isValid;
			}
		);
};

export const stationSchema = yup.object().shape({
	name: yup.string().default('').required(),
	address: yup.string().default('').required(),
	note: yup.string().nullable().default(''),
	postalCode: yup.string().nullable().default(''),
	contact: yup.object().shape({
		phoneNumber: phoneNumberSchema,
		name: yup.string().default(''),
	}),
	lat: yup.number().nullable().default(null),
	lng: yup.number().nullable().default(null),
});

export const licensePlateNumberSchema = yup
	.string()
	.default('')
	.matches(
		/^[A-Z0-9]{1,4}-[A-Z0-9]{1,4}$/,
		i18next.t('driverAssign:validation.licensePlateNumber')
	);

export const stringArrayUniqueSchema = (
	schema: yup.AnySchema,
	errorMessage: string
) => {
	return schema.test(function (values: string[]) {
		if (!Array.isArray(values)) return true;
		const valuesSet = new Set(values);
		if (valuesSet.size === values.length) return true;
		for (let i = 0; i < values.length; i++) {
			const value = values[i];
			if (valuesSet.has(value)) {
				valuesSet.delete(value);
			} else {
				throw this.createError({
					path: `${this.path}.${i}`,
					message: errorMessage,
				});
			}
		}
		return true;
	});
};

export const shippingTypeSchema = yup
	.array()
	.default([])
	.min(1, i18next.t('schema:atLeastPickOne'));

export const dateSchema = yup
	.number()
	.nullable()
	.default(startOfTodayUnixTime)
	.test(
		'miminum-is-today',
		i18next.t('shippingOrder:dateMustNotSmallerThanToday'),
		(unixTime) => {
			const isValid = unixTime ? unixTime >= startOfTodayUnixTime : true;
			return isValid;
		}
	);

export const waitingHourSchema = numberSchema({
	max: 48,
	step: 0.5,
	isPositive: true,
});

export const moneySchema = (props?: IAmountSchema) => {
	const { min, max } = props ?? {};
	return numberSchema({
		min,
		max,
		isInteger: true,
		isPositive: true,
	});
};
export const palletSchema = () => {
	return yup.object().shape({
		palletCount: positiveNumberSchema.required(),
		boxCount: positiveNumberSchema,
		weight: positiveNumberSchema.required(),
	});
};

export const boxSchema = () => {
	return yup.object().shape({
		weight: positiveNumberSchema.required(),
		boxCount: positiveNumberSchema.required(),
	});
};

export const vehicleSchema = () => {
	return yup.object().shape({
		palletCount: positiveNumberSchema.required().test((value, context) => {
			const { vehicleSize: vehicleSizeString } = context.parent;
			const vehicleSize = JSON.parse(vehicleSizeString);
			const { palletLimit } = vehicleSize;
			if (value && value > palletLimit) {
				return context.createError({
					message: i18next.t('shippingOrder:schema.palletMaximum', {
						max: palletLimit,
					}),
				});
			}
			return true;
		}),
		vehicleSize: yup.string().nullable().default('').required(),
		boxCount: positiveNumberSchema,
		weight: positiveNumberSchema.required(),
	});
};

export const contractServiceSchema = yup.object({
	pallet: yup.object({
		rePalletizing: positiveNumberSchema,
		inbound: positiveNumberSchema,
		waitingTime: positiveNumberSchema,
	}),
	multistop: yup.object({
		baseStation: zeroAndPositiveNumberSchema,
		exceedStation: positiveNumberSchema,
	}),
});
