import { ReactElement, useMemo, useState } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { useMeasure } from 'react-use';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import {
	Popper,
	MenuItem,
	ListSubheader,
	InputAdornment,
	Divider,
	Box,
	Stack,
	Checkbox,
	ClickAwayListener,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import i18next from 'i18next';
import FormLabel from './FormLabel';
import TextField from './TextField';

interface Option<T> {
	label: string;
	value?: string | number;
	data?: T;
}

interface GroupItem<T> {
	groupKey: string;
	options: Option<T>[];
}

type GroupOption<T> = { type: 'header'; text: string } | Option<T>;

const group = <T,>(arr: Option<T>[], groupBy: Function) => {
	let group: GroupItem<T>[] = [];
	for (const option of arr) {
		const groupKey = groupBy(option);
		const currentGroup = group.find(
			(group: any) => group.groupKey === groupKey
		);
		if (currentGroup) {
			currentGroup.options.push(option);
		} else {
			group.push({ groupKey, options: [option] });
		}
	}
	let result: GroupOption<T>[] = [];
	for (const groupItem of group) {
		groupItem.groupKey &&
			result.push({ type: 'header', text: groupItem.groupKey });
		result = result.concat(groupItem.options);
	}
	return result;
};

interface MultiSearchSelectorProps<T> {
	name: string;
	label: string;
	required?: boolean;
	placeholder?: string;
	onChangeSubmit?: Function;
	onOptionChange?: Function;
	options: Option<T>[];
	groupBy?: Function;
	singleChoice?: boolean;
	clearButtonColor?: string;
	customizedAction?: ReactElement;
}

const MultiSearchSelector = <T,>({
	name,
	label = '',
	options,
	groupBy,
	onChangeSubmit,
	singleChoice = false,
	clearButtonColor = 'color.warning',
	customizedAction,
}: MultiSearchSelectorProps<T>) => {
	const theme = useTheme();
	const {
		control,
		formState: { errors },
		getValues,
		handleSubmit,
	} = useFormContext();
	const [ref, { width }] = useMeasure();
	const [searchText, setSearchText] = useState<string>('');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		if (anchorEl) {
			anchorEl.focus();
		}
		setAnchorEl(null);
	};
	return (
		<div ref={ref as any}>
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange: onFieldChange, ...fieldProps } }) => {
					const errorMsg = get(errors, name)?.message;
					const isError = !!errorMsg;
					const { value: values } = fieldProps;
					const selectedCount = options.reduce((acc, cur) => {
						const { value } = cur;
						if (Array.isArray(value)) {
							if (value.every((v) => values.indexOf(v) >= 0)) acc += 1;
						} else if (values.indexOf(value) >= 0) {
							acc += 1;
						}
						return acc;
					}, 0);
					return (
						<>
							<Box
								aria-describedby={'button'}
								onClick={handleClick}
								sx={{
									width: '100%',
									padding: '9px 12px',
									display: 'flex',
									justifyContent: 'space-between',
									borderRadius: '8px',
									border: '1px solid',
									borderColor: open ? 'color.allyBlue' : '#23191614',
									bgcolor: 'color.white',
									'&:hover': {
										bgcolor: 'color.white',
									},
									cursor: 'pointer',
								}}
							>
								<Box>
									<Typography
										className='Customized-select'
										component={'span'}
										sx={{ color: theme.palette.primary.light }}
									>
										{label}
									</Typography>
									{selectedCount > 0 && (
										<Box
											component={'span'}
											sx={{
												p: '2px 8px',
												bgcolor: 'color.blue',
												borderRadius: 2,
												ml: 1.5,
												color: 'white',
											}}
										>
											{selectedCount}
										</Box>
									)}
								</Box>
								{open ? (
									<KeyboardArrowUpIcon sx={{ fontSize: 22 }} />
								) : (
									<KeyboardArrowDownIcon sx={{ fontSize: 22 }} />
								)}
							</Box>
							<Popper
								id={'popper'}
								open={open}
								anchorEl={anchorEl}
								placement='bottom-start'
								sx={{
									border: `1px solid #23191614`,
									boxShadow: `0 8px 24px rgba(0, 0, 0, 0.07)`,
									borderRadius: '8px',
									width: `${width}px`,
									minWidth: '150px',
									zIndex: theme.zIndex.appBar,
									backgroundColor: 'white',
									p: 1.5,
								}}
							>
								<ClickAwayListener onClickAway={handleClose}>
									<div>
										<Box
											sx={{
												pb: '2px',
												bgcolor: 'color.white',
											}}
										>
											<TextField
												sx={{ width: '100%' }}
												label={
													<FormLabel required label={i18next.t('search')} />
												}
												value={searchText}
												onChange={(e) => {
													e.stopPropagation();
													setSearchText(e.target.value);
												}}
												error={isError}
												helperText={errorMsg}
												className={'Customized-small'}
												variant='standard'
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<SearchIcon />
														</InputAdornment>
													),
												}}
											/>
											<Stack
												direction='row'
												justifyContent='flex-end'
												spacing={1}
												my={1}
											>
												{customizedAction}
												<Typography
													component={'span'}
													sx={{
														textAlign: 'end',
														fontSize: '14px',
														color: clearButtonColor,
														cursor: 'pointer',
													}}
													onClick={() => {
														if (values?.length) {
															onFieldChange([]);
															if (onChangeSubmit) {
																const formData = getValues();
																handleSubmit(onChangeSubmit(formData));
															}
														}
														if (searchText) {
															setSearchText('');
														}
													}}
												>
													{i18next.t('clear')}
												</Typography>
											</Stack>
											<Divider />
										</Box>
										<Box sx={{ overflow: 'auto', maxHeight: '242px' }}>
											<Options
												values={values}
												options={options}
												searchText={searchText}
												groupBy={groupBy}
												onFieldChange={onFieldChange}
												onChangeSubmit={onChangeSubmit}
												singleChoice={singleChoice}
											/>
										</Box>
									</div>
								</ClickAwayListener>
							</Popper>
						</>
					);
				}}
			/>
		</div>
	);
};

interface Options<T> {
	values: any;
	options: Option<T>[];
	searchText: string;
	groupBy?: Function;
	onFieldChange: Function;
	onChangeSubmit?: Function;
	singleChoice: boolean;
}

const Options = <T,>({
	values,
	options,
	searchText,
	groupBy,
	onFieldChange,
	onChangeSubmit,
	singleChoice,
}: Options<T>) => {
	const { handleSubmit, getValues } = useFormContext();
	const { filterdOptions, isNoResult } = useMemo(() => {
		const filteredOptions = options.filter((option) => {
			return option.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
		});
		const isNoResult = !filteredOptions.length;
		let result: any = filteredOptions;

		if (groupBy) {
			result = group(filteredOptions, groupBy);
		}
		return { filterdOptions: result, isNoResult };
	}, [options, searchText, groupBy]);
	if (isNoResult) {
		return (
			<Typography
				sx={{
					mt: 1,
					fontSize: '14px',
					color: 'color.black60',
					whiteSpace: 'break-spaces',
				}}
			>
				{i18next.t('noResult')}
			</Typography>
		);
	}
	return filterdOptions.map((option: any, index: number) => {
		if (option?.type === 'header') {
			return (
				<ListSubheader
					key={index}
					sx={{
						lineHeight: 0,
						py: 1.5,
						pl: '4px',
						color: 'color.info',
						fontWeight: 400,
						whiteSpace: 'break-spaces',
					}}
				>
					{option?.text}
				</ListSubheader>
			);
		} else {
			const { label, value } = option;
			let checked = false;
			if (Array.isArray(value)) {
				checked = value.every((v) => values?.indexOf(v) > -1);
			} else {
				checked = values?.indexOf(value) > -1;
			}
			return (
				<MenuItem
					key={label}
					value={value}
					sx={{ p: '2px', my: 0.5 }}
					onClick={() => {
						if (singleChoice) {
							onFieldChange(checked ? [] : [value]);
						} else {
							const data = new Set(values);
							if (Array.isArray(value)) {
								if (checked) {
									for (const v of value) {
										data.delete(v);
									}
								} else {
									for (const v of value) {
										data.add(v);
									}
								}
							} else {
								data.has(value) ? data.delete(value) : data.add(value);
							}
							onFieldChange(Array.from(data));
						}
						if (onChangeSubmit) {
							const formData = getValues();
							handleSubmit(onChangeSubmit(formData));
						}
					}}
				>
					<Checkbox
						checked={checked}
						sx={{ p: 0, mr: '6px', alignSelf: 'flex-start' }}
					/>
					<Typography
						sx={{
							fontSize: '14px',
							color: 'color.black60',
							whiteSpace: 'break-spaces',
						}}
					>
						{label}
					</Typography>
				</MenuItem>
			);
		}
	});
};

export default MultiSearchSelector;
