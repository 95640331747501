// https://github.com/react-dates/react-dates/issues/934
import { useEffect } from 'react';
import { get, useWatch, useFormContext } from 'react-hook-form';
import { useImmer } from 'use-immer';
import moment from 'moment';
import { DateRangePicker as AirbnbDateRangePicker } from 'react-dates';
import { Box, FormHelperText } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'moment/locale/zh-tw';
import 'react-dates/initialize';
import './DateRangePicker.css';

moment.locale('zh-tw');

const defaultState = {
	focusedInput: null,
	mStartDate: null,
	mEndDate: null,
	startDate: null,
	endDate: null,
};

const DateRangePicker = (props) => {
	const {
		setValue,
		formState: { errors },
		handleSubmit,
		getValues,
	} = useFormContext();
	const [startDateValue, endDateValue] = useWatch({
		name: [props.startDateId, props.endDateId],
	});
	const [state, updateState] = useImmer(defaultState);
	const errorMsg =
		get(errors, props.startDateId)?.message ||
		get(errors, props.endDateValue)?.message;
	const { isMobile, ...airbnbDateRangePickerProps } = props;
	if (isMobile) {
		airbnbDateRangePickerProps.orientation = 'vertical';
		airbnbDateRangePickerProps.verticalHeight = 630;
	}
	useEffect(() => {
		if (startDateValue !== state.startDate || endDateValue !== state.endDate) {
			updateState((draft) => {
				if (startDateValue !== draft.startDate) {
					const nextStartDate = startDateValue ? Number(startDateValue) : null;
					const nextmStartDate = nextStartDate
						? moment(nextStartDate * 1000)
						: null;

					draft.startDate = nextStartDate;
					draft.mStartDate = nextmStartDate;
				}
				if (endDateValue !== draft.endDate) {
					const nextEndDate = endDateValue ? Number(endDateValue) : null;
					const nextmEndDate = nextEndDate ? moment(nextEndDate * 1000) : null;
					draft.endDate = nextEndDate;
					draft.mEndDate = nextmEndDate;
				}
			});
		}
	}, [startDateValue, endDateValue]);
	return (
		<>
			<AirbnbDateRangePicker
				navPrev={<NavPrev />}
				navNext={<NavNext />}
				focusedInput={state.focusedInput}
				startDate={state.mStartDate}
				endDate={state.mEndDate}
				startDatePlaceholderText={props.startDatePlaceholderText}
				endDatePlaceholderText={props.endDatePlaceholderText}
				monthFormat='MMMM YYYY[年]'
				phrases={{ closeDatePicker: '關閉', clearDates: '清除日期' }}
				displayFormat='MM[月]DD[日]'
				isOutsideRange={props.isOutsideRange}
				onFocusChange={(currFocusedInput) => {
					updateState((draft) => {
						draft.focusedInput = currFocusedInput;
					});
				}}
				onDatesChange={({ startDate: mStartDate, endDate: mEndDate }) => {
					let startDate = mStartDate?.startOf('day').unix();
					let endDate = mEndDate?.endOf('day').unix();
					startDate = startDate ? String(startDate) : null;
					endDate = endDate ? String(endDate) : null;
					setValue(props.startDateId, startDate);
					setValue(props.endDateId, endDate);
					updateState((draft) => {
						draft.mStartDate = mStartDate;
						draft.mEndDate = mEndDate;
						draft.startDate = startDate;
						draft.endDate = endDate;
					});
					if (props.onChangeSubmit) {
						const formData = getValues();
						handleSubmit(props.onChangeSubmit(formData));
					}
				}}
				{...airbnbDateRangePickerProps}
			/>
			{errorMsg && <FormHelperText>{errorMsg}</FormHelperText>}
		</>
	);
};

DateRangePicker.defaultProps = {
	minimumNights: 0,
	regular: false,
	keepOpenOnDateSelect: false,
	reopenPickerOnClearDates: false,
	isRTL: false,
	showDefaultInputIcon: true,
	showClearDates: true,
	disabled: false,
	required: false,
	startDateId: 'startDate',
	endDateId: 'endDate',
	isMobile: false,
	isOutsideRange: () => false,
};

const NavPrev = () => {
	return (
		<Box
			sx={{
				position: 'absolute',
				top: '18px',
				left: '22px',
			}}
		>
			<ArrowBackIosNewIcon fontSize='inherit' />
		</Box>
	);
};

const NavNext = () => {
	return (
		<Box
			sx={{
				position: 'absolute',
				top: '18px',
				right: '22px',
			}}
		>
			<ArrowForwardIosIcon fontSize='inherit' />
		</Box>
	);
};

export default DateRangePicker;
