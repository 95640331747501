import { lazy } from 'react';
import i18next from 'i18next';
import { isAdminURL, isCarrierURL, isPorterURL } from '~/utils/urls';
import Layout from '~/components/Layout';
import RequireAuth from '~/components/RequireAuth';
import type { RouteObject } from 'react-router-dom';
import * as PalletPaths from '~/pages/Pallet';
import * as MultistopPaths from '~/pages/Multistop';
import * as ContainerPaths from '~/pages/Container';
import * as TrackingPaths from '~/pages/Tracking';
import * as ShipperPaths from '~/pages/Shippers';
import * as CarriersPaths from '~/pages/Carriers';
import * as BillingPaths from '~/pages/Billing';
import * as DriversPaths from '~/pages/Drivers';
import * as SettingPaths from '~/pages/Setting';
import * as DashboardPaths from '~/pages/Dashboard';
import * as InitiatePaths from '~/pages/Initiate';
import * as LoginPaths from '~/pages/Login';
import * as ResetPasswordPaths from '~/pages/ResetPassword';
import * as RolesPaths from '~/pages/Roles';
import * as DriverCalendarPaths from '~/pages/DriverCalendar';
import { SHIPPING_TYPE, SHIPPING_TYPE_PATHS } from '~/enums/app';
import { ReactComponent as PalletIcon } from '~/static/menu/pointtopoint.svg';
import { ReactComponent as MultistopIcon } from '~/static/menu/multistop.svg';
import { ReactComponent as ContainerIcon } from '~/static/menu/container.svg';
import { ReactComponent as DriverIcon } from '~/static/menu/driver.svg';
import { ReactComponent as ShipperIcon } from '~/static/menu/shipper.svg';
import { ReactComponent as CarrierIcon } from '~/static/menu/carrier.svg';
import { ReactComponent as SettingIcon } from '~/static/menu/setting.svg';
import { ReactComponent as ContractIcon } from '~/static/menu/contract.svg';
import { ReactComponent as BillIcon } from '~/static/menu/bill.svg';
import { ReactComponent as BillingIcon } from '~/static/menu/billing.svg';
import { ReactComponent as pendingBillIcon } from '~/static/menu/pendingBill.svg';

const NotFoundPage = lazy(() => import('~/pages/NotFoundPage'));

const devRoute = process.env.ENV_TYPE !== 'production' ? [] : [];

const routes: RouteObject[] = [
	{
		path: '/',
		element: (
			<>
				<Layout />
			</>
		),
		children: [
			{
				path: '/',
				children: [
					{
						hidden: !isAdminURL,
						index: true,
						path: '/',
						breadcrumb: '',
						element: (
							<RequireAuth>
								<DashboardPaths.DashboardAdminPage />
							</RequireAuth>
						),
					},
					{
						hidden: !isPorterURL,
						index: true,
						path: '/',
						breadcrumb: '',
						element: (
							<RequireAuth>
								<DashboardPaths.DashboardShipperPage />
							</RequireAuth>
						),
					},
					{
						hidden: !isCarrierURL,
						index: true,
						path: '/',
						breadcrumb: '',
						element: (
							<RequireAuth>
								<DashboardPaths.DashboardCarrierPage />
							</RequireAuth>
						),
					},
				],
			},
			{
				hidden: !isAdminURL,
				path: `${SHIPPING_TYPE_PATHS.PALLET}`,
				isSideMenuItem: true,
				breadcrumb: i18next.t('shipping.type.pallet'),
				icon: PalletIcon,
				element: (
					<RequireAuth>
						<PalletPaths.PalletAdminPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isPorterURL,
				shippingType: SHIPPING_TYPE.PALLET,
				path: `${SHIPPING_TYPE_PATHS.PALLET}`,
				breadcrumb: i18next.t('shipping.type.pallet'),
				isSideMenuItem: true,
				icon: PalletIcon,
				element: (
					<RequireAuth>
						<PalletPaths.PalletShipperPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isCarrierURL,
				shippingType: SHIPPING_TYPE.PALLET,
				path: `${SHIPPING_TYPE_PATHS.PALLET}`,
				breadcrumb: i18next.t('shipment.type.pallet'),
				isSideMenuItem: true,
				icon: PalletIcon,
				element: (
					<RequireAuth>
						<PalletPaths.PalletCarrierPage />
					</RequireAuth>
				),
			},
			{
				hidden: isCarrierURL,
				shippingType: SHIPPING_TYPE.PALLET,
				path: `${SHIPPING_TYPE_PATHS.PALLET}/shipping-order-detail/:id`,
				breadcrumb: 'shipping-order-detail',
				element: (
					<RequireAuth>
						<PalletPaths.PalletShippingOrderDetailPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isAdminURL,
				shippingType: SHIPPING_TYPE.PALLET,
				path: `${SHIPPING_TYPE_PATHS.PALLET}/shipping-order`,
				breadcrumb: 'shipping-order',
				element: (
					<RequireAuth>
						<PalletPaths.PalletAdminShippingOrderPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isPorterURL,
				shippingType: SHIPPING_TYPE.PALLET,
				path: `${SHIPPING_TYPE_PATHS.PALLET}/shipping-order`,
				breadcrumb: 'shipping-order',
				element: (
					<RequireAuth>
						<PalletPaths.PalletShipperShippingOrderPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isCarrierURL,
				shippingType: SHIPPING_TYPE.PALLET,
				path: `${SHIPPING_TYPE_PATHS.PALLET}/shipment-detail/:id`,
				breadcrumb: 'shipment-detail',
				element: (
					<RequireAuth>
						<PalletPaths.PalletShipmentDetailPage />
					</RequireAuth>
				),
			},

			{
				hidden: !isAdminURL,
				path: `${SHIPPING_TYPE_PATHS.MULTISTOP}`,
				breadcrumb: i18next.t('multistop'),
				isSideMenuItem: true,
				icon: MultistopIcon,
				element: (
					<RequireAuth>
						<MultistopPaths.MultistopAdminPage />
					</RequireAuth>
				),
			},
			{
				hidden: isCarrierURL,
				shippingType: SHIPPING_TYPE.MULTISTOP,
				path: `${SHIPPING_TYPE_PATHS.MULTISTOP}/shipping-order-detail/:id`,
				breadcrumb: 'shipping-order-detail',
				element: (
					<RequireAuth>
						<MultistopPaths.MultistopShippingOrderDetailPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isPorterURL,
				shippingType: SHIPPING_TYPE.MULTISTOP,
				path: `${SHIPPING_TYPE_PATHS.MULTISTOP}`,
				breadcrumb: i18next.t('menu.item.multistop'),
				isSideMenuItem: true,
				icon: MultistopIcon,
				element: (
					<RequireAuth>
						<MultistopPaths.MultistopShipperPage />
					</RequireAuth>
				),
			},
			{
				hidden: isCarrierURL,
				shippingType: SHIPPING_TYPE.MULTISTOP,
				path: `${SHIPPING_TYPE_PATHS.MULTISTOP}/shipping-order`,
				breadcrumb: 'shipping-order',
				element: (
					<RequireAuth>
						<MultistopPaths.MultistopShippingOrderPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isCarrierURL,
				shippingType: SHIPPING_TYPE.MULTISTOP,
				path: `${SHIPPING_TYPE_PATHS.MULTISTOP}`,
				breadcrumb: i18next.t('menu.item.multistop.shipment'),
				isSideMenuItem: true,
				icon: MultistopIcon,
				element: (
					<RequireAuth>
						<MultistopPaths.MultistopCarrierPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isCarrierURL,
				shippingType: SHIPPING_TYPE.MULTISTOP,
				path: `${SHIPPING_TYPE_PATHS.MULTISTOP}/shipment-detail/:id`,
				breadcrumb: 'shipment-detail',
				element: (
					<RequireAuth>
						<MultistopPaths.MultistopShipmentDetailPage />
					</RequireAuth>
				),
			},
			...devRoute,
			{
				hidden: !isAdminURL,
				path: `${SHIPPING_TYPE_PATHS.CONTAINER}`,
				breadcrumb: i18next.t('shipping.type.container'),
				isSideMenuItem: true,
				icon: ContainerIcon,
				element: (
					<RequireAuth>
						<ContainerPaths.ContainerAdminPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isPorterURL,
				shippingType: SHIPPING_TYPE.CONTAINER,
				path: `${SHIPPING_TYPE_PATHS.CONTAINER}`,
				breadcrumb: i18next.t('shipping.type.container'),
				isSideMenuItem: true,
				icon: ContainerIcon,
				element: (
					<RequireAuth>
						<ContainerPaths.ContainerShipperPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isPorterURL,
				shippingType: SHIPPING_TYPE.CONTAINER,
				path: `${SHIPPING_TYPE_PATHS.CONTAINER}/shipping-order`,
				breadcrumb: 'shipping-order',
				element: (
					<RequireAuth>
						<ContainerPaths.ContainerShippingOrderPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isPorterURL,
				shippingType: SHIPPING_TYPE.CONTAINER,
				path: `${SHIPPING_TYPE_PATHS.CONTAINER}/shipping-order/:id`,
				breadcrumb: 'shipping-order',
				element: (
					<RequireAuth>
						<ContainerPaths.ContainerShippingOrderPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isCarrierURL,
				path: `${SHIPPING_TYPE_PATHS.CONTAINER}`,
				shippingType: SHIPPING_TYPE.CONTAINER,
				breadcrumb: i18next.t('menu.item.container.shipment'),
				isSideMenuItem: true,
				icon: ContainerIcon,
				element: (
					<RequireAuth>
						<ContainerPaths.ContainerCarrierPage />
					</RequireAuth>
				),
			},
			{
				path: '/shippers',
				hidden: !isAdminURL,
				isSideMenuItem: true,
				breadcrumb: i18next.t(`shipper`),
				icon: ShipperIcon,
				element: (
					<RequireAuth>
						<ShipperPaths.ShippersPage />
					</RequireAuth>
				),
			},
			{
				path: '/shippers/:unifiedBusinessNo',
				hidden: !isAdminURL,
				breadcrumb: i18next.t(`shipper`),
				element: (
					<RequireAuth>
						<ShipperPaths.ShipperDetailPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isAdminURL,
				path: '/shippers/:unifiedBusinessNo/contract/:contractId',
				breadcrumb: 'contract-detail',
				element: (
					<RequireAuth>
						<ShipperPaths.ShipperContracPage />
					</RequireAuth>
				),
			},
			{
				path: '/carriers',
				hidden: !isAdminURL,
				breadcrumb: i18next.t(`carrier`),
				isSideMenuItem: true,
				icon: CarrierIcon,
				element: (
					<RequireAuth>
						<CarriersPaths.CarriersPage />
					</RequireAuth>
				),
			},
			{
				path: '/carriers/:unifiedBusinessNo',
				hidden: !isAdminURL,
				breadcrumb: i18next.t(`carrier`),
				element: (
					<RequireAuth>
						<CarriersPaths.CarrierDetailPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isAdminURL,
				path: '/carriers/:unifiedBusinessNo/contract/:contractId',
				breadcrumb: 'contract-detail',
				element: (
					<RequireAuth>
						<CarriersPaths.CarrierContractPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isCarrierURL,
				path: '/drivers',
				breadcrumb: i18next.t('driver:driverManagement'),
				isSideMenuItem: true,
				icon: DriverIcon,
				element: (
					<RequireAuth>
						<DriversPaths.DriverListPage />
					</RequireAuth>
				),
			},
			{
				path: '/billing',
				hidden: isCarrierURL,
				icon: BillingIcon,
				breadcrumb: i18next.t(`accounting`),
				children: [
					{
						hidden: !isPorterURL,
						isSideMenuItem: true,
						path: '/billing/contract',
						icon: ContractIcon,
						breadcrumb: i18next.t(`contract`),
						element: (
							<RequireAuth>
								<BillingPaths.ContractListShipperPage />
							</RequireAuth>
						),
					},
					{
						hidden: !isPorterURL,
						path: '/billing/contract/detail/:contractId',
						element: (
							<RequireAuth>
								<BillingPaths.ContractDetailShipperPage />
							</RequireAuth>
						),
					},
					{
						hidden: !isAdminURL,
						isSideMenuItem: true,
						path: '/billing/pendingBill',
						breadcrumb: i18next.t(`pendingBill`),
						icon: pendingBillIcon,
						element: (
							<RequireAuth>
								<BillingPaths.PendingBillPage />
							</RequireAuth>
						),
					},
					{
						hidden: !isAdminURL,
						path: '/billing/bills',
						breadcrumb: i18next.t(`billing`),
						isSideMenuItem: true,
						icon: BillIcon,
						element: (
							<RequireAuth>
								<BillingPaths.BillsAdminPage />
							</RequireAuth>
						),
					},
					{
						hidden: !isPorterURL,
						path: '/billing/bills',
						breadcrumb: i18next.t(`billing`),
						isSideMenuItem: true,
						icon: BillIcon,
						element: (
							<RequireAuth>
								<BillingPaths.BillsShipperPage />
							</RequireAuth>
						),
					},
					{
						hidden: isCarrierURL,
						path: '/billing/bills/:id/:type',
						breadcrumb: i18next.t(`billing`),
						element: (
							<RequireAuth>
								<BillingPaths.BillPreviewPage />
							</RequireAuth>
						),
					},
				],
			},
			{
				path: '/setting',
				hidden: !isPorterURL,
				isSideMenuItem: true,
				icon: SettingIcon,
				breadcrumb: i18next.t(`setting`),
				element: (
					<RequireAuth>
						<SettingPaths.SettingShipperPage />
					</RequireAuth>
				),
			},
			{
				path: '/setting',
				hidden: !isCarrierURL,
				isSideMenuItem: true,
				icon: SettingIcon,
				breadcrumb: i18next.t(`setting`),
				element: (
					<RequireAuth>
						<SettingPaths.SettingCarrierPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isCarrierURL,
				path: `${SHIPPING_TYPE_PATHS.CONTAINER}/shipment-detail/:id`,
				breadcrumb: 'container-shipment-detail',
				element: (
					<RequireAuth>
						<ContainerPaths.ContainerShipmentDetailPage />
					</RequireAuth>
				),
			},
			{
				hidden: isCarrierURL,
				path: `${SHIPPING_TYPE_PATHS.CONTAINER}/shipping-order-detail/:id`,
				breadcrumb: 'shipping-order-detail',
				element: (
					<RequireAuth>
						<ContainerPaths.ContainerShippingOrderDetailPage />
					</RequireAuth>
				),
			},
			{
				hidden: !isAdminURL,
				path: `/roles`,
				breadcrumb: i18next.t('role:role.manager'),
				icon: SettingIcon,
				isSideMenuItem: true,
				element: (
					<RequireAuth>
						<RolesPaths.RolesPage />
					</RequireAuth>
				),
			},
		],
	},
	{
		path: '/initiate/:token',
		element: <InitiatePaths.InitiatePage />,
	},
	{
		path: '/login',
		element: <LoginPaths.LoginPage />,
	},
	{
		path: '/reset-password/:token',
		element: <ResetPasswordPaths.ResetPasswordPage />,
	},
	{
		path: '/tracking/:encryptedId',
		element: <TrackingPaths.PalletTrackingPage />,
	},
	{
		hidden: !isAdminURL,
		path: '/driver-calendar',
		element: <DriverCalendarPaths.DriverCalendarPage />,
	},
	{
		path: '*',
		breadcrumb: 'Not Found',
		element: (
			<RequireAuth>
				<NotFoundPage />
			</RequireAuth>
		),
	},
];

function removeHiddenRoutes(currRoutes: RouteObject[]) {
	const newRoutes: RouteObject[] = [];
	currRoutes.forEach((routeObject) => {
		if (!routeObject.hidden) {
			if (Array.isArray(routeObject.children)) {
				routeObject.children = removeHiddenRoutes(routeObject.children);
			}
			newRoutes.push(routeObject);
		}
	});

	return newRoutes;
}

export default removeHiddenRoutes(routes);
