import axios from 'axios';
import store from 'store2';
import { getTokenData } from '~/libraries/jwt';
import { apiURL } from '~/utils/urls';
import getRolePath from '~/utils/getRolePath';

const TEN_SECONDS = 25;

async function fetchToken() {
	const refreshToken = store.get('refreshToken');
	if (!refreshToken) throw new Error('No refresh token available');
	const tokenData = getTokenData();
	if (tokenData?.exp) {
		const now = new Date().getTime() / 1000;
		if (tokenData.exp - now > TEN_SECONDS) {
			return store.get('token');
		}
	}
	const refreshUrl = `${apiURL}${getRolePath('/auth/refresh/$ROLE-user')}`;
	const response = await axios.post(refreshUrl, {
		token: refreshToken,
	});
	const token = response?.data?.token;
	store.set('token', token);
	return token;
}

export default fetchToken;
